import React from "react"
import { Link } from 'gatsby';
import App from "../../components/App"
//import Cards from '../../components/Cards';


export default function Contributors() {
  return (
    <App>
      <div className='right-frame'>
        <div className='wrapper'>
      		<ContributorCard
        		img='/brij-bhushan.jpg'
        		name='Brij Bhushan'
        		description='Researcher'
        		label='Researcher'
        		contr_path='/contributors/brijbhushan'
      		/>
      	</div>
      </div>
    </App>
  )  
}

function ContributorCard(props) {
  return (
    <>
      	<div className='card'>
      		<figure className='card_pic-wrap' data-category={props.label}>
        		<img src={props.img} className='card_img' alt={props.name}></img>
        	</figure>
        	<div className='card__body'>
          		<h2 className='card__title'>{props.name}</h2>
          		<p className='card__description'>{props.description}</p>
     			<button className='card__btn'>
              		<Link className='btn_link' to={props.contr_path}>
                		Details
              		</Link>
            	</button>
            </div>
        </div>
    </>
  );
}